import React from "react";
import * as styles from "./AboutSection.module.css";
import { GatsbyImage } from "gatsby-plugin-image";

const AboutSection = ({ slice }) => {
  console.log(slice);
  return (
    <div className={styles.container}>
      <div className={styles.overlay}></div>
      <GatsbyImage
        className={styles.image}
        image={slice.primary.main_image.gatsbyImageData}
        alt="About Image"
      />
      <div className={styles.content}>
        <h2>{slice.primary.title.text}</h2>
        <p>{slice.primary.description.text}</p>
      </div>
    </div>
  );
};

export default AboutSection;
