import React from 'react'
import { Facebook, Instagram, LinkedIn } from '../../helpers/icons.js'
import { useStaticQuery, graphql } from 'gatsby'

const SocialIcons = ({ styles }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicSettings {
        nodes {
          data {
            facebook_link {
              raw
            }
            instagram_link {
              raw
            }
            linkedin_link {
              raw
            }
            mobile_phone {
              raw
            }
            e_mail {
              raw
            }
          }
        }
      }
    }
  `)
  return (
    <ul className={styles.ul}>
      <li>
        <a
          href={data.allPrismicSettings.nodes[0].data.e_mail.raw.url}
          title="Send me an e-mail"
          rel="external noreferrer noopener nofollow"
          target="_blank"
          className={`${styles.link} link_effect`}
        >
          mariavrdk@gmail.com
        </a>
      </li>
      <li>
        <a
          href={data.allPrismicSettings.nodes[0].data.facebook_link.raw.url}
          title="Follow me on Facebook"
          rel="external noreferrer noopener nofollow"
          target="_blank"
          className={styles.link}
        >
          <Facebook />
          <span className="visually-hidden">Facebook</span>
        </a>
      </li>
      <li>
        <a
          href={data.allPrismicSettings.nodes[0].data.instagram_link.raw.url}
          title="Follow me on Instagram"
          rel="external noreferrer noopener nofollow"
          target="_blank"
          className={styles.link}
        >
          <Instagram />
          <span className="visually-hidden">Instagram</span>
        </a>
      </li>
      <li>
        <a
          href={data.allPrismicSettings.nodes[0].data.linkedin_link.raw.url}
          title="Follow me on LinkedIn"
          rel="external noreferrer noopener nofollow"
          target="_blank"
          className={styles.link}
        >
          <LinkedIn />
          <span className="visually-hidden">LinkedIn</span>
        </a>
      </li>
    </ul>
  )
}

export default SocialIcons
