import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import { Seo } from '../components/meta/Seo'
import SliceZone from '../components/slices/SliceZone'

const Category = (props) => {
  return (
    <Layout>
      <Seo title={props.data.prismicCategory.data.category_title.text} />
      <SliceZone slices={props.data.prismicCategory.data.body} />
    </Layout>
  )
}
export const query = graphql`
  query Category($id: String) {
    prismicCategory(id: { eq: $id }) {
      data {
        category_title {
          text
        }
        body {
          ... on PrismicCategoryDataBodyImageGallery {
            id
            items {
              image {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                alt
              }
            }
            slice_label
            slice_type
            primary {
              gallery_title {
                text
              }
            }
          }
        }
      }
    }
  }
`
export default Category
