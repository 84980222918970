import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import MenuModal from "../elements/MenuModal";
import BackToTop from "../../helpers/BackToTop";

import "../../styles/styles.css";

const Layout = ({ children }) => {
  const [open, isOpen] = useState(false);

  const menuState = (state) => {
    if (state) {
      isOpen(true);
    } else {
      isOpen(false);
    }
  };
  return (
    <>
      <div className="site">
        <Header state={menuState} />
        {children}
        <Footer />
        <BackToTop />
      </div>
      {open && <MenuModal state={menuState} />}
    </>
  );
};

export default Layout;
